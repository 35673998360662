<template>
    <v-card outlined class='mt-2'>
        <v-container>
                <v-row>
                    <v-col cols=12>
                        <h3 class="text-h5 font-weight-light">Internet of Things</h3>
                    </v-col>
                </v-row>
                <v-row align="center" justify='center'>
                    <v-col cols=12 lg=7 class="text-justify">
                        L'explosion des objets connectés amènent de plus souvent les entreprises à développer leurs
                        propres
                        objets connectés, aux fonctionnalités spécifiques.
                        Je vous accompagne dans la définition de votre besoin, la conception et la programmation de vos
                        objets.<br />
                        Partisan d'une approche hardware minimaliste, je propose des solutions techniques pertinentes et
                        bon
                        marché, afin de diminuer au maximum les coûts de fabrication des solutions série.
                    </v-col>
                    <v-col cols=12 sm=8 lg=5>
                        <v-img aspect-ratio="1.778" src="@/assets/img/cards/iot/nodeyt.jpg" class="rounded"></v-img>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols=12>
                        <h3 class="text-h5 font-weight-light">Développement et Programmation</h3>
                    </v-col>
                </v-row>
                <v-row align="center" class="flex-lg-row-reverse" justify='center'>
                    <v-col cols=12 lg=7 class="text-justify">
                        Nous avons la chance de pouvoir programmer même les plus simples des micro-contrôleurs à l'aide
                        de
                        langages puissants, orientés objets, tels que le C++.
                        Cela permet au client final, lors des revues de code ou de la maintenance et mises à jour
                        firmwares,
                        de disposer d'un code aéré, propre et lisible.<br />
                        Que vous ayiez besoin de solutions complexes (ex : un objet connecté tournant avec un noyau
                        Linux
                        pour des fonctionnalités avancées) ou plus simples, à l'aide d'un simple stack WiFi / Bluetooth,
                        vous disposez d'une solution adaptée à vos besoins.
                    </v-col>
                    <v-col cols=12 sm=8 lg=5>
                        <v-img aspect-ratio="1.778" src="@/assets/img/cards/iot/ide.jpg" class="rounded"></v-img>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols=12>
                        <h3 class="text-h5 font-weight-light">Prototypage</h3>
                    </v-col>
                </v-row>
                <v-row align="center" justify='center'>
                    <v-col cols=12 lg=7 class="text-justify">
                        Des capacités de prototypage rapides sont essentielles, en utilisant des composants standards et
                        facilement intégrables, je suis capable de proposer des solutions de prototypage rapide
                        ne prévenant pas l'usage futur de ces mêmes composants dans des solutions plus intégrées.<br />
                        Ci-contre, un prototype de solution miniature d'envoi de SMS, reposant sur un Raspberry Pi 3A+
                        et
                        une carte WWAN 4G LTE
                    </v-col>
                    <v-col cols=12 sm=8 lg=5>
                        <v-img aspect-ratio="1.778" src="@/assets/img/cards/iot/simmodule.jpg" class="rounded"></v-img>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols=12>
                        <h3 class="text-h5 font-weight-light">Hardware et Debug</h3>
                    </v-col>
                </v-row>
                <v-row align="center" class="flex-lg-row-reverse" justify='center'>
                    <v-col cols=12 lg=7 class="text-justify">
                        Fort de plusieurs années d'expérience en R&D électromécanique, je peux prendre en charge la
                        conception, réalisation et debuggage des cartes électroniques de vos objets connectés et
                        hardwares
                        spécifiques.
                        <br />Afin de concevoir et réaliser vos cartes prototypes, j'utilise des outils Open-Source et
                        des
                        formats standards, vous permettant la transmission à des bureaux d'études spécialisés dans la
                        finalisation série de vos cartes électroniques.
                    </v-col>
                    <v-col cols=12 sm=8 lg=5>
                        <v-img aspect-ratio="1.778" src="@/assets/img/cards/iot/hardware.jpg" class="rounded"></v-img>
                    </v-col>
                </v-row>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        data(){
            return{
                iot: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>